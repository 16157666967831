@media (max-width: $screen-md-max) {
	$login-container-w: 990px;
	$login-kocka-w: $login-container-w / 6 - ($login-kocka-margin * 2);

	@import "login-page-meretek";

	.login-quote {
		background-position: 5px 10px;
		padding: 15px;

		blockquote {
			font-size: $font-size-base;
		}
	}

	.login-form {
		padding: 15px 30px;
	}

	.login-avatar img {
		height: 70px;
	}

	.header-idezet {
		font-size: 18px;
	}

	.probateremfoglalas-terem {
		padding: 1em;
	}
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.balmenupont-kocka-link {
		font-size: 12px;
	}
}