@media print {
	.header,
	.balmenu {
		display: none;
	}

	.foglalas-hely {
		background: white;
		border: 1px solid black;
		margin: 0.2%;
		flex-basis: calc(19.6% - 20px);
		&.foglalt {
			color: #000;
		}

		&.ures,
		&.lepcso {
			background: transparent !important;
		}
	}

	.beosztas-nap{
		display: table-row;
	}
	.beosztas-nap>*{
		display: table-cell !important;
		border: 1px solid #bbb;
		font-size: 14px;
		color: #000;
		padding: 2px 5px;
		span{
			background: none;
			font-size: 14px;
			border: none;
			padding: 0;
		}
		strong{
			font-weight: normal;
		}
	}
	hr{
		display: none;
	}
	.btn, label{
		display: none;
	}
	.helyszin-label{
		display: none;
	}
	.print-header{
		display: table-row !important;
		>div{
			display: table-cell;
			border: 1px solid #bbb;
			font-size: 14px;
			color: #000;
			padding: 2px 5px;
		}
	}
	.kotojel{
		display: none;
	}
}
.print-header{
	display: none;
}
