@import "login-page-meretek";

.login-page {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $login-kocka-margin 0;
}

.login-page>.container-flex {
	padding: 0;
	max-width: 100%;
}

.login-kocka-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% / 3);
}

.login-kocka {
	background-color: #f7f1e0;
}

.login-form {
	padding: 20px 40px;
	background: #ddd3b8;

	.form-group {
		margin: 0;
	}
}

.login-title {
	margin: 10px 0;
	font-size: 18px;
	color: $brand-primary;
	text-align: center;
	text-transform: uppercase;
}

.login-quote {
	background: $brand-primary url("../../images/quote-sign.svg") 15px 20px no-repeat;
	color: #fff;
	padding: 30px;

	blockquote {
		border: 0 none;
		margin: 0 0 5px;
		font-size: 15px;
		font-style: italic;
		line-height: 1.3;
		padding: 0;

		&:before {
			content: "\201e";
		}

		&:after {
			content: "\201d";
		}
	}

	*{
		font-style: italic;
	}

	cite {
		font-style: italic;
		font-weight: 300;
	}
}

.login-avatar{
	p{
		margin-top: 14px;
		font-size: 18px;
	}
}
