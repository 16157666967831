.beosztas-szolamok {
	margin-top: 1em;
}

.beosztas-szolam {
	display: inline-block;
	font-size: 12px;
	line-height: 1.1;
	margin: 5px 0;

	&+& {
		margin-left: 10px;
		border-left: 1px solid $text-color;
		padding-left: 10px;
	}
}

.beosztas-nap{
	.idopont, .nev, .jelleg{
		display: inline;
	}
}
