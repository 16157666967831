@media (max-width: $grid-float-breakpoint-max) {
	.balmenu {
		position: fixed;
		top: 0;
		left: -100vw;
		right: 100vw;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		background: #fff url("../../images/pfz-logo.png") 10px 10px no-repeat;
		background-size: auto 4rem;
		z-index: 900;
		margin: 0;
		padding: 6rem 6px 3px;
		@include transition(all .4s cubic-bezier(0.4, 0, 1, 1));

		&.open {
			left: 0;
			right: 0;

			.balmenu-toggle {
				position: absolute;
				right: 0;
				top: 0;
				padding: 15px;
			}
		}

		&.auth_utan {
			position: absolute;
			top: -60px;

			.balmenu-toggle {
				display: none !important;
			}
		}
	}

	.main-container {
		position: relative;
	}

	.balmenu-toggle {
		font-size: 3rem;
		padding: 20px 0;
		margin-left: 20px;
		display: flex !important;
		align-items: center;
	}

	.balmenu-toggle-label {
		font-size: 12px;
		text-transform: uppercase;
		margin-right: 5px;
	}

	.header-idezet {
		width: auto;
		font-size: 14px;
		font-weight: normal;
		padding: 20px 20px 20px 80px;
		background: $brand-primary none;
	}

	.main-content {
		margin-left: 0;
	}

	.esemeny-listaelem-kep {
		margin: 0 0 1em;
	}
}