$login-container-w: $container-lg !default;
$login-kocka-margin: 3px !default;
$login-kocka-w: $login-container-w / 6 - ($login-kocka-margin * 2) !default;

@mixin login-kocka {
	width: $login-kocka-w;
	height: $login-kocka-w;
	margin: $login-kocka-margin;
	display: flex;
}

.login-page>.container-flex {
	width: $login-container-w + $login-kocka-margin * 2;
}

.login-kocka {
	@include login-kocka;
}

.login-kocka-2x1 {
	@include login-kocka;
	width: $login-kocka-w * 2 + $login-kocka-margin * 2;
}

.login-kocka-2x2 {
	@include login-kocka;
	width: $login-kocka-w * 2 + $login-kocka-margin * 2;
	height: $login-kocka-w * 2 + $login-kocka-margin * 2;
}
