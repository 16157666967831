a {
	@include transition(all .2s ease-in-out);
}

a.inverz {
	color: $text-color;

	&:hover,
	&:focus {
		color: $link-color;
	}
}

.form-control {
	box-shadow: none;
}

.btn {
	font-weight: 500;
	text-transform: uppercase;
}

.nomargin {
	margin: 0;
}

.main-container {
	margin-top: 60px;
}


.main-content {
	max-width: 100%;
	flex-grow: 1;
	margin-left: 30px;
	position: relative;
	margin-bottom: 50px;
}

.main-content-title {
	color: transparentize($text-color, .5);
	font-size: 14px;
	text-align: center;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 1.5em;
	position: absolute;
	bottom: 100%;
}

.main-content-title-warning {
	color: $brand-primary;
	font-size: 14px;
	text-align: center;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0.8em;
	position: absolute;
	bottom: 100%;
	left: 25%;

	a {
		text-transform: lowercase;
		text-decoration: underline;
	}
}

.kedvezmeny {
	padding-bottom: $grid-gutter-width;
	margin-bottom: $grid-gutter-width;
	border-bottom: 1px solid #e6e6e6;
}

.kedvezmeny-kep {
	flex: 0 0 150px;
	margin-right: $grid-gutter-width;
}

.kedvezmeny-nev {
	margin-top: 0;
	font-size: 20px;
}

.kedvezmeny-mertek {
	font-size: 18px;
	font-style: italic;
}

.dokumentum {
	padding: 5px 0;
	border-bottom: 1px solid #e6e6e6;
}

.dokumentum-cim {
	font-weight: normal;
	font-size: 14px;
}

.dokumentum-datum {
	color: #808080;
	font-size: 14px;
	align-self: center;
}

.testuleti-hely {
	border: 1px solid #e6e6e6;
	padding: 20px;
	margin-bottom: 20px;
}

.alcim,
.testuleti-hely-nev {
	font-size: 20px;
	text-transform: uppercase;
	margin-top: 10px;
}

.testulet-tag {
	flex: 0 0 calc(50% - #{$grid-gutter-width} / 2);
	margin-bottom: $grid-gutter-width;
}

.testulet-tag-kep {
	flex: 0 0 120px;
	margin-right: 20px;
}

.testulet-tag-nev {
	margin-top: 0;
	font-size: 20px;
}

.testulet-tag-feladat {
	font-style: italic;
}

.bold {
	font-weight: bold;
}

.datetimepicker {
	border-radius: 0;
}

.pagination {
	display: flex;
	justify-content: center;
}

.selectpicker {
	opacity: 0;
}

.bootstrap-select {
	max-width: 100%;

	.dropdown-toggle {
		border: 0;
		background: #f5f5f5;
	}

	.bs-caret {
		position: absolute;
		display: block;
		width: 26px;
		height: 20px;
		top: 50%;
		right: 4px;
		@include translate(0, -50%);
		border-left: 1px solid #e6e6e6;
	}

	.caret {
		margin: 0 !important;
		border: 0;
		width: 15px;
		height: 8px;
		background: url("data:image/svg+xml,%3Csvg version='1.1' class='bootstrap-select-caret' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 240.8 132.6' xml:space='preserve' width='15px' height='8px'%3E%3Cg%3E%3Cpath fill='%23ccc' id='Chevron_Right' d='M129,129.1L237.3,20.8c4.7-4.8,4.7-12.5,0-17.2c-4.7-4.8-12.4-4.8-17.2,0l-99.7,99.7L20.7,3.6 C16-1.2,8.3-1.2,3.6,3.6c-4.7,4.8-4.7,12.5,0,17.2l108.3,108.3C116.5,133.8,124.3,133.8,129,129.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
		background-size: cover;
		position: static !important;
		@include transform-origin(50% 50%);
	}
}

.label {
	border-radius: 0;
	font-size: 15px;
	font-weight: normal;
}

.aloldal-menu {
	margin-bottom: 30px;
}

.aloldal-menupont {
	background: $drapp;
	border: 5px solid #fff;
	color: transparentize($text-color, .5);
	width: 130px;
	height: 130px;

	&:hover,
	&:focus {
		background: $brand-primary;
		color: #fff;
	}
}

.aloldal-menupont-title {
	margin: 20px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
}

.btn-secondary {
	@include button-variant($text-color, $drapp-vilagos, $drapp-vilagos);
}

.pfz-datepicker {
	.datetimepicker-inline {
		width: 100%;
	}

	td,
	th {
		border-radius: 0;
	}

	table {
		width: 100%;
		background: $drapp-vilagos;
		box-shadow: 0 0 10px rgba(0, 0, 0, .2);
		font-size: 12px;

		>thead>tr:first-child th {
			padding-top: 10px;
			padding-bottom: 10px;

			&,
			&:hover {
				background: #e5ddc6;
				color: $text-color;
			}
		}
	}

	.dow {
		border-bottom: 1px solid $drapp;
		color: $brand-primary;
	}

	.day {
		color: #76635b;
		font-weight: 400;
		@include transition(all .25s);
	}

	/*
	.active {
		background-color: $brand-primary !important;
		color: $drapp-vilagos !important;
	}
	*/

	.highlight {
		background: $drapp !important;
	}

	.highlight-sajat {
		background: transparentize($brand-primary, .15);
		color: $drapp-vilagos !important;

		&:hover {
			color: $brand-primary !important;
		}
	}

	.glyphicon {
		color: $brand-primary;
		padding: 3px;
		font-size: .85em;
		font-weight: 300;
		border-radius: 50%;
		border: 2px solid $brand-primary;
		vertical-align: middle;
	}

	.prev .glyphicon {
		@extend .glyphicon-menu-left;
	}

	.next .glyphicon {
		@extend .glyphicon-menu-right;
	}
}