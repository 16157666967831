.esemeny-listaelem {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e6e6e6;
}

.esemeny-listaelem-kep {
	margin-right: 20px;
}

.esemeny-listaelem-cim {
	font-size: 20px;
	margin-top: 0;
}

.esemeny-cikk {
	font-size: 15px;
	h4 {
		margin-top: 25px;
	}
}

.esemeny-sharelink {
	background: $drapp-vilagos;
	font-size: 18px;
	margin-right: 4px;
	flex: 0 0 40px;
	padding-left: 0;
	padding-right: 0;

	&:hover, &:focus {
		background: $drapp;
		color: $text-color;
	}
}

.musor li{
	margin-bottom: 10px;
}
