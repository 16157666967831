@media (max-width: $screen-xs-max){
	.login-kocka-wrapper {
		width: 100vw;
	}

	$login-container-w: 100vw;
	$login-kocka-margin: 1vw;
	$login-kocka-w: ($login-container-w - ($login-kocka-margin * 2)) / 2 - ($login-kocka-margin * 2);

	@import "login-page-meretek";

	.login-page > .container-flex {
		padding: 0 $login-kocka-margin;
	}

	.login-kocka.foto,
	.login-kocka.ures {
		display: none;
	}

	.login-tools {
		font-size: 12px;
	}

	.login-form {
		min-height: $login-kocka-w * 2 + $login-kocka-margin * 2;
		height: auto;

		.btn-primary {
			margin: 10px 0;
		}
	}

	.kedvezmeny-kep {
		margin-bottom: 15px;
	}

	.header {
		background-position: 0 0;
		background-size: cover;
		padding: 0 0 15px;
	}

	.header-idezet {
		position: static;
		@include translate(0,0);
		max-width: none;
		min-height: 130px;
	}

	.header-content {
		margin-top: 0;
	}

	.header-usermenu {
		order: 1;
		flex: 1 0 100%;
		flex-wrap: wrap;
		justify-content: center;

		li:first-child {
			flex: 1 0 100%;
			justify-content: center;
			padding-top: 10px;
		}

		li:nth-child(2):before {
			display: none;
		}
	}

	.header-logo {
		order: 2;
		padding: 0;
	}

	.header .balmenu-toggle {
		order: 3;
		padding: 13px 0;
	}

	$foglalas-hely-w: 145px;

	.foglalas-helyek {
		overflow: scroll;
		width: calc(100vw - #{$grid-gutter-width});
		display: block;
	}

	.foglalas-helyek-wrap {
		width: ($foglalas-hely-w + 20px) * 5;
	}

	.foglalas-hely {
		flex: 0 0 $foglalas-hely-w;
		overflow: hidden;
	}

	.table-xs {
		table-layout: fixed;

		>thead {
			display: none;
		}

		>tbody>tr {
			display: block;
		}

		>tbody>tr>td,
		>tbody>tr>th, {
			display: block;
			padding-top: $table-cell-padding / 4;
			padding-bottom: $table-cell-padding / 4;

			&:first-child {
				background: #f2f2f2;
			}

			&:last-child {
				border-bottom: 2px solid #d5d5d5;
			}

			&:not(:first-child) {
				border-top: 0;
			}

			&.text-right,
			&.text-center {
				text-align: left;
			}

			&[data-title]:before {
				content: attr(data-title)": ";
				font-weight: bold;
			}
		}
	}
}
