@import url('//fonts.googleapis.com/css?family=Roboto:300,400,400i,700&subset=latin-ext');

@import "bootstrap";
@import "bootstrap-flexbox";
@import "datetimepicker";
@import "fullcalendar";
@import "forms";

@import "../../../public/vendor/fancybox/dist/jquery.fancybox.min";
@import "../../../public/vendor/font-awesome/scss/font-awesome";
@import "../../../public/vendor/bootstrap-select/sass/bootstrap-select";

@import "layout";
@import "login-page";
@import "header";
@import "balmenu";
@import "uzenofal";
@import "esemenyek";
@import "foglalas";
@import "beosztas";
@import "tavolmaradasi";
@import "probateremfoglalas";

@import "app-md";
@import "app-breakpoint";
@import "app-sm";
@import "app-xs";

@import "print";

@import "app-ie";

.label-warning, .label-danger, .label-success {
    color: white;
}