.post {
	border: 1px solid $drapp;
	padding: 20px;
	margin-bottom: 20px;
}

.post-szerzo {
	order: 1;
}

.post-title {
	order: 2;
	font-size: 20px;
	margin: 1em 0;
}

.post-szerzo-avatar {
	margin-right: 20px;
}

.post-szerzo-nev {
	margin: 0;
	font-size: 18px;
}

.post-datum {
	color: #808080;
	line-height: 1;
}

.post-content {
	font-size: 15px;
	overflow: hidden;
}

.post-footer {
	border-top: 1px solid $drapp;
	padding-top: 15px;
	margin-top: 15px;

	a {
		font-weight: bold;
		font-size: 12px;
	}
}

.post-attachments {
	margin-top: 20px;
	flex-wrap: wrap;
}

.post-kommentek {
	margin-top: 3em;
}

.komment {
	border-bottom: 1px solid $drapp;
	padding-bottom: 20px;
	margin: 20px 0;
}

.komment-szerzo {
	margin-bottom: 1em;
}

.komment-szerzo-avatar {
	margin-right: 10px;
}

.komment-szerzo-nev {
	margin: 0;
	font-size: 18px;
}

.komment-datum {
	color: #808080;
	line-height: 1;
	font-size: 12px;
}

.komment-content {
	font-size: 13px;
}

.uj-uzenet-form {
	display: none;
}
