@mixin balmenupont-kozos {
	display: flex;
	justify-content: center;
	margin: 3px 0;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;
	padding: 12px;

	&, a {
		color: transparentize($text-color, .5);
	}
}

.balmenu {
	flex: 0 0 calc(25% - 20px);
	margin-right: 30px;
	margin-top: -3px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: space-between;
	position: relative;

	.badge {
		right: 10px;
		padding: 7px;
		display: block;
		position: absolute;
		border-radius: 50%;
		background: $brand-primary;
	}

	svg {
		polygon,
		path {
			fill: $brand-primary;
		}
	}
}

.balmenupont-szeles {
	@include balmenupont-kozos;
	align-items: center;
	padding: 0 45px;
	height: 65px;
	background: $drapp;
	flex: 0 0 100%;

	* {
		@include transition(all .2s ease-in-out);
	}

	&:hover {
		background-color: $brand-primary;
		color: #fff;

		svg path {
			fill: #fff;
		}

		.badge {
			background-color: $link-hover-color;
		}
	}

	.badge, svg {
		top: 50%;
		@include translate(0, -50%);
	}

	svg {
		display: block;
		position: absolute;
		left: 10px;
	}
}

.balmenupont-well {
	@include balmenupont-kozos;
	background: $maple;
	flex: 0 0 100%;
}

.balmenupont-well-title {
	font-size: $font-size-base;
	text-align: center;
	margin: 0 0 12px;
}

.balmenu-szolgalat-item {
	flex: 0 0 50%;
	display: flex;
	color: $text-color;
	text-transform: none;
	flex-direction: column;
	text-align: center;
}

.balmenupont-well-content.list-unstyled {
	margin: 0;
}

.balmenu-szolgalat-label {
	font-size: 11px;
	font-weight: 400;
}

.balmenu-szolgalat-value {
	font-size: 16px;
	font-weight: bold;
}

.balmenupont-kocka {
	@include balmenupont-kozos;
	width: calc(50% - 3px);
	display: block;
	//padding-bottom: calc(50% - 15px);

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

/*
.balmenupont-kocka-link-wrap {
	display: flex;
	padding: 0 0 100%;
	position: relative;
	width: 100%;
	flex: 1 0 100%;
}
*/

.balmenupont-kocka-link {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $drapp;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5px;

	&.dark {
		background-color: $text-color;
		color: #9a9287;
	}

	svg {
		margin-bottom: 12px;
		height: 36px;
		width: 36px;
	}

	.badge {
		top: 10px;
	}

	&:hover {
		background-color: $brand-primary !important;
		color: #fff;

		svg path,
		svg polygon {
			fill: #fff;
		}

		.badge {
			background-color: $link-hover-color;
		}
	}

	&.active {
		background-color: $maple;
	}
}
