.header {
	background: url("../../images/header-bg.png") 50% 0 no-repeat;
}

.header-usermenu {
	margin: 0;
	display: flex;

	>li {
		display: flex;
		align-items: center;

		&:not(:first-child):before {
			content: "|";
			padding-right: 10px;
			display: inline-block;
		}
	}

	.btn-logout {
		padding-left: 0;
		padding-right: 0;
	}
}

.header-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	padding: 7px 0;
	max-width: 119px;
}

.header-content {
	z-index: 5;
	text-align: center;
}

.header-kep {
	position: relative;
	display: inline-block;
}

.header-idezet {
	background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='422' height='361' viewBox='0 0 422 361'%3E%3Cdefs%3E%3Cstyle%3E .idezet-bg-path %7B fill: %23e95e0f; fill-rule: evenodd; opacity: 0.9; %7D %3C/style%3E%3C/defs%3E%3Cpath class='idezet-bg-path' d='M422 0L136.08 1 0 231l41 130 381-1V0z'/%3E%3C/svg%3E") 100% 50% no-repeat;
	color: #fff;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 422px;
	font-size: 18px;
	font-style: italic;
	font-weight: bold;
	line-height: 1.2;
	padding: 40px 40px 40px 90px;
	text-align: right;
	display: flex;
	align-items: center;

	>p {
		margin: 0;
	}
}
