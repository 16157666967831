.probateremfoglalas-datepicker {
	max-width: 320px;
	width: 100%;

	.datetimepicker-days > table {
		table-layout: fixed;
	}
}

.probateremfoglalas-terem {
	padding: 1em 2em;
}

.probateremfoglalas-terem-nev {
	background: $drapp-vilagos;
	border: 1px solid #e6e6e6;
	margin: 0;
	padding: 10px 0;
}

.probateremfoglalas-idopont {
	margin: 0;
	padding: 4px;
	border: 1px solid #e6e6e6;
	border-top-width: 0;
	position: relative;
	@include user-select(none);

	&.foglalt {
		background: transparentize($brand-primary, .3);
		border-color: $brand-primary;
		border-top-width: 1px;
		margin-top: -1px;
		padding-top: 5px;
		padding-left: 24px;
		padding-right: 24px;
		color: #fff;
	}
}

.probateremfoglalas-torles {
	position: absolute;
	top: 6px;
	right: 6px;
	color: #fff;
}

.probateremfoglalas-checkbox {
	&:checked + .probateremfoglalas-label {
		color: $brand-primary;
		@include transition(all .25s);

		&:after {
			content: "\e013";
		}
	}
}

.probateremfoglalas-label {
	cursor: pointer;
	position: relative;
	margin: 0;
	line-height: 1.2;

	&:after {
		@include transition(all 0s);
		@extend .glyphicon;
		display: block;
		position: absolute;
		top: 0;
		left: calc(100% + 5px);
		bottom: 0;
	}
}
