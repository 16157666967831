.bekuldott-tavolmaradasi-form{
  margin: 160px 0 0 0;
}

.tavolmaradasi-uzenet{
    margin-bottom: 7px;
    word-wrap: break-word;
	word-break: break-all;
	background: #f3f3f3;
	padding: .5em;

	&:last-child {
		margin-bottom: 0;
	}

	&.sajat {
		background: transparent;
	}

	&.sajat + &.sajat {
		border-top: 1px solid #f3f3f3;
	}
}

.tavolmaradasi-uzenet-datum {
	font-size: 12px;
	display: block;
	text-align: right;
}

.tavolmaradasi-uzenet-felado {
	color: $brand-primary;

	.sajat & {
		color: $text-color;
	}
}

.tavolmaradasi-beszelgetes{
    height: 300px;
    overflow:auto;
    border:1px solid #ccc;
    padding:7px;
}

#tavolmaradasi-beszelgetes-form{
    margin-top:5px;
}

#tavolmaradasi-uzenet-input{
    min-width: 100%;
   min-height: 100px;
   resize: none;
}

#tamogatas{
    height:100px;
}
