html.no-flexboxlegacy {
	.login-page {
		display: block;
	}

	.balmenu {
		width: 25%;
	}

	.balmenupont {
		&-well,
		&-szeles {
			width: 100%;
			-ms-flex: none;
		}

		&-kocka-link > .text-center {
			width: 100%;
		}
	}

	.main-content {
		width: 75%;
	}
}