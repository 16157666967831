.foglalas-helyek {
	border: 1px solid #e6e6e6;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
}

.foglalas-helyek-wrap {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	&.ie11 .foglalas-hely{
		height: 40px;
	}
}

.foglalas-hely {
	//flex: 1 1 calc(20% - 20px);
	//width: calc(20% - 20px);
	width: 14rem;
	border: 5px solid #fff;
	background: $drapp;
	height: 33px;
	display: flex;
	align-content: center;
	padding: 5px;
	box-sizing: content-box;

	&.vezeto {
		flex-basis: 40%;
	}

	&.lepcso {
		flex-basis: 60%;
	}

	&.vezeto,
	&.lepcso {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.lepcso,
	&.ures {
		background: #f6f6f6;
		color: #808080;
	}

	&.foglalt {
		background: $maple;
	}
}

.foglalas-hely-sorszam {
	font-weight: bold;
	font-size: 18px;
	flex: 0 0 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid $drapp-vilagos;
	margin-left: -5px;
}

.foglalas-hely-form-wrap {
	flex-grow: 1;
	@include transition(all .25s);
}

.foglalas-hely-form {
	height: 100%;
	position: relative;
}

.foglalas-hely-torles,
.foglalas-hely-submit {
	@include transition(all .25s);
	@include transform-origin(0 0);
	@include rotateY(90deg);
	position: absolute;
	top: 0;
	left: 80%;
	bottom: 0;
	z-index: 1;
}

.foglalas-hely-input {
	width: 100%;
	height: 100%;
	border: 1px solid transparent;
	background: transparent;
	padding-left: 5px;
	outline: none;
	@include transition(all .25s);
	z-index: 0;
	&:hover {
		background: $drapp-vilagos;
	}

	&:focus {
		background: $drapp-vilagos;
		border-color: $brand-primary;
	}

	&:focus+.foglalas-hely-submit, &+.foglalas-hely-submit:focus{
		@include rotateY(0);
	}
}

.foglalas-hely-nev {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 5px;
	position: relative;
	border: 1px solid transparent;
	font-size: 12px;
	line-height: 16px;
	cursor: default;
	@include transition(all .25s);

	&:focus,
	&:hover {
		border: 1px solid $brand-danger;
		background: $drapp-vilagos;
	}

	&:focus .foglalas-hely-torles,
	&:hover .foglalas-hely-torles {
		@include rotateY(0);
	}
}
